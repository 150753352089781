import { t } from "@lingui/macro"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { MDXRenderer } from "gatsby-plugin-mdx"
import React from "react"
import { Seo } from "../components/seo"
import { BaseLayout } from "../layouts/base-layout"

const BlogPost = ({ data }) => {
  if (!data.mdx) return null

  const image = getImage(data.mdx.frontmatter.hero_image)

  return (
    <BaseLayout>
      <Seo
        title={data.mdx.frontmatter.title}
        description={
          data.mdx.frontmatter.description ||
          t`Find your best profile photos. Whether you're testing professional, social, or dating profile picture, we can help you to make the right impression.`
        }
      />

      <div className="mx-auto prose prose-lg md:prose-xl">
        <p>{data.mdx.frontmatter.date}</p>
        {data.mdx.frontmatter.hero_image_alt && (
          <GatsbyImage
            image={image}
            alt={data.mdx.frontmatter.hero_image_alt}
          />
        )}

        {data.mdx.frontmatter.hero_image_credit_link && (
          <p>
            Photo Credit:{" "}
            <a href={data.mdx.frontmatter.hero_image_credit_link}>
              {data.mdx.frontmatter.hero_image_credit_text}
            </a>
          </p>
        )}

        <MDXRenderer>{data.mdx.body}</MDXRenderer>
      </div>
    </BaseLayout>
  )
}

export const query = graphql`
  query ($locale: String!, $slug: String!) {
    mdx(
      fields: { locale: { eq: $locale } }
      frontmatter: { slug: { eq: $slug } }
    ) {
      body
      frontmatter {
        title
        description
        slug
        date(formatString: "LL", locale: $locale)
        hero_image_alt
        hero_image_credit_link
        hero_image_credit_text
        hero_image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`

export default BlogPost
